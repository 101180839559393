<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deletePollBlock" />
              <app-button-close route-name="pollBlock_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">

            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered table-hover">
            <tbody>
            <tr>
              <th>Id</th>
              <td>{{ pollBlock.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('pollBlock.title') }}</th>
              <td>{{ pollBlock.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('pollBlock.identifier') }}</th>
              <td>{{ pollBlock.identifier }}</td>
            </tr>
            <tr>
              <th>{{ $t('pollBlock.site') }}</th>
              <td>{{ siteTitleById(pollBlock.site) }}</td>
            </tr>
            <tr>
              <th>{{ $t('pollBlock.poll') }}</th>
              <td>{{ pollBlock.poll }}</td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="pollBlock"></app-detail-system-rows>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import PollBlockModel from '../../model/PollBlockModel.js'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import DetailSystemRows from '../../components/shared/DetailSystemRows'
import SiteService from '../../services/site/SiteService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PollBlockView',
  data () {
    return {
      pollBlock: PollBlockModel,
      error: null
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows
  },
  computed: {
    isDeleteButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_POLL_BLOCK_PERMISSIONS.deleteButton
      return this.$store.getters['user/hasPermission'](permission)
    }
  },
  methods: {
    getPollBlock () {
      this.$store.dispatch('pollBlock/fetchOne', this.$route.params.id)
        .then(() => {
          this.pollBlock = this.$store.getters['pollBlock/detail']
        })
    },
    deletePollBlock () {
      this.$store.dispatch('pollBlock/deleteRecord', this.pollBlock)
        .then(() => {
          if (this.$store.getters['pollBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/pollBlock')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
          }
        })
        .catch(error => console.log(error))
    },
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    }
  },
  mounted () {
    this.getPollBlock()
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
